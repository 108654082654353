import {Injectable} from '@angular/core';
import {FirebaseFinal} from './firebase-final';
import {AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument} from '@angular/fire/firestore';

@Injectable({
    providedIn: 'root'
})
export class FirebaseFinalService {
    matchCollection: AngularFirestoreCollection<FirebaseFinal>;

    constructor(private readonly afs: AngularFirestore) {
        this.matchCollection = this.afs.collection('final');
    }

    getFinalMatches() {
        return this.matchCollection.snapshotChanges();
    }

    create(data: FirebaseFinal) {
        return this.matchCollection.doc(data.id).set(data);
    }

    delete(id: string) {
        return this.afs.doc<FirebaseFinal>(`final/${id}`).delete();
    }

    update(id: string, data) {
        return this.afs.doc<FirebaseFinal>(`final/${id}`).update(data);
    }
}
