import {Injectable} from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument} from '@angular/fire/firestore';
import {FirebaseMatch} from './firebase-match';

@Injectable({
    providedIn: 'root'
})
export class FirebaseMatchService {
    matchCollection: AngularFirestoreCollection<FirebaseMatch>;
    matchCollectionP1P2: AngularFirestoreCollection<FirebaseMatch>;
    matchCollectionP1: AngularFirestoreCollection<FirebaseMatch>;
    matchCollectionP2: AngularFirestoreCollection<FirebaseMatch>;
    matchDoc: AngularFirestoreDocument<FirebaseMatch>;

    private p1Id;
    private p2Id;

    constructor(private readonly afs: AngularFirestore) {
        this.matchCollection = this.afs.collection('match');
    }

    getMatches() {
        return this.matchCollection.snapshotChanges();
    }

    getP1P2Matches(p1Id, p2Id) {
        this.p1Id = p1Id;
        this.p2Id = p2Id;
        this.matchCollectionP1P2 = this.afs.collection('match',
            ref => ref.where('p1Id', '==', this.p1Id).where('p2Id', '==', this.p2Id));
        return this.matchCollectionP1P2.snapshotChanges();
    }

    getPlayerP1Matches(p1Id) {
        this.matchCollectionP1 = this.afs.collection('match', ref => ref.where('p1Id', '==', p1Id));

        return this.matchCollectionP1.snapshotChanges();
    }

    getPlayerP2Matches(p1Id) {
        this.matchCollectionP2 = this.afs.collection('match',
            ref => ref.where('p2Id', '==', p1Id));

        return this.matchCollectionP2.snapshotChanges();
    }

    getSingleMatchData(id: string) {
        this.matchDoc = this.afs.doc<FirebaseMatch>(`match/${id}`);
        return this.matchDoc.valueChanges();
    }

    create(data: FirebaseMatch) {
        return this.matchCollection.add(data);
    }

    delete(id: string) {
        return this.afs.doc<FirebaseMatch>(`match/${id}`).delete();
    }

    update(id: string, data) {
        return this.afs.doc<FirebaseMatch>(`match/${id}`).update(data);
    }
}
